var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "wrapper" } },
    [
      _c("error-messages", { attrs: { messages: _vm.error_messages } }),
      _c("div", { staticClass: "text-center" }, [
        _vm._v("Адресс "),
        _c("b", [_vm._v(_vm._s(_vm.email) + " ")]),
        _vm._v("не зарегистрирован"),
      ]),
      _c("div", { staticClass: "form-actions center" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { id: "register" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.registerHander.apply(null, arguments)
              },
            },
          },
          [_vm._v("Зарегистрироваться")]
        ),
      ]),
      _c("div", { staticClass: "form-actions center" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-default",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.goBack.apply(null, arguments)
              },
            },
          },
          [_vm._v("Назад")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }